.ProductCard {
    @include desktop {
        &-Picture {
            transform: scale(1) !important;
        }
    }

    @include mobile {
        padding: 1rem !important;

        .AddToCart {
            min-width: 9rem !important;
            font-size: 1rem;
        }
    }

    &-AddToWrapper {
        justify-content: center !important;
    }
    
    &-Name {
        h2 {
            text-align: center !important;
        }
    }

    .AddToCart {
        background-color: #A9824A;
        --button-border: #A9824A;

        &.Button:not([disabled]):hover {
            background-color: #7C5F36;
            border-color: #7C5F36;   
        }
    }
}