/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --store-social-list-icon-color: var(--footer-menu-link-color);
}

.StoreSocial {
    &-Title {
        span {
            color: var(--primary-dark-color);
        }
    }

    &-List {
        @include desktop {
            max-width: 24rem;
        }

        display: flex;
        flex-wrap: wrap;

        @include mobile {
            justify-content: center;
        }
    }

    &-ListIcon {
        &:before {
            content: none;
        }

        padding: {
            left: 1rem;
            right: 1rem;
        }

        @include desktop {
            &:first-of-type, &:nth-of-type(5) {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }



        > *,
        a:visited,
        a:hover {
            color: var(--store-social-list-icon-color);
            display: flex;
            flex-direction: column;
            text-decoration: none;
        }

        .fab {
            font-size: 2.6rem;
            text-align: center;
            margin-bottom: .5rem;
        }

        &Title {
            font-size: .9rem;
        }
    }
}
