.SiteUsp {
    &-Content {
        --site-usp-font-size: 1.4rem;
    }

    &-List {
        justify-content: space-evenly;
        width: 100%;
    }
    
}