.HomePage {
    @include mobile {
        .LayoutGroupWidget-Container_layout_col_3_1 {
            .LayoutGroupWidget-Item .BannerGroup-Container {
                .swiper-slide {
                    width: 100% !important;
                }
            }
        }
    }

    @include desktop {
        .LayoutGroupWidget-Container_layout_col_3_1 {
            display: flex;
    
            .LayoutGroupWidget-Item:first-of-type {
                width: calc(100% - 56rem);
            }
    
            .LayoutGroupWidget-Item:last-of-type {
                width: 56rem;
            }
        }
    }
}

.BannerGroup {
    &-Container {
        padding: 0 !important;

        .BannerItem {
            --banner-slider-height: 71.1rem;
            
            &-ContentBlock {
                padding:0 !important;
            }

            &-Title {
                padding: 2rem 3rem;
                font-size: 3rem !important;
            }

            &-Link {
                font-size: 1.5rem;
                text-transform: none;
                font-weight: 400;
                line-height: 1.5;
                padding: 2rem 8rem 2rem 3rem;
                color: #000;
                background-color: #E5E5E5;
                height: 12.5rem;
                width: 100%;

                &:after {
                    content: '\3009';
                    font-size: 2.3rem !important;
                    color: var(--primary-light-color);
                    position: absolute;
                    right: 0;
                    top: 33%;
                    transform: scale(2, 1);
                    font-weight: 100;
                    

                }

                &.Button {
                    &:hover {
                        padding: 2rem 8rem 2rem 3rem;
                        height: 12.5rem;
                    }
                }
            }
        }

        &.banners-small {
            .BannerItem {

                &-Title {
                    padding: 2rem 3rem;
                    font-size: 3rem !important;
                }

                &-Link {
                    font-size: 2rem;
                    border:0;
                    text-transform: uppercase;
                    font-weight: 700;
                    padding: 2rem 5rem 2rem 3rem;
                    color: white;
                    background-color: var(--primary-base-color);
                    width: 100%;
                    height: 7.5rem;

                    &:after {
                        font-size: 2.8rem;
                        top: 33%;
                        color: #fff;
                    }
                }
            }
        }
    }
}


@include desktop {
    .HomePage {
        padding-top: 2rem;
    }
    
    .BannerItem  {
        &_isSlider {
            min-height: 71.2rem !important;
        }
    }
}

@include mobile {
    .HomePage {
        padding-top: 2rem;
    }

    .BannerItem  {
        &_isSlider {
            min-height: 40rem !important;

            .BannerItem-Link {
                text-align: left;
                padding: 1rem 4rem 1rem 1.5rem;
            }
        }
    }
}