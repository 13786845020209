:root {
    --content-wrapper-width: 144rem !important;
}

.HeaderMenu {
    &-MenuWrapper {
        --header-menu-main-item-margin-after-tablet: 0 3% 0 0;
    }
}

.SiteUsp {
    &-List {
        --site-usp-icon-color: var(--secondary-base-color);
        margin: auto;
    }
}

.FooterMenu {
    &-List {
        --footer-menu-link-color: var(--footer-column-content-color);
    }
}

.StoreSocial {
    &-List {
        --store-social-list-icon-color: white;
    }
}

.Button {
    background-color: #A9824A;
    --button-border: #A9824A;
    border-radius: 0;

    &.Button:not([disabled]):hover {
        background-color: #7C5F36;
        border-color: #7C5F36;
    }

    &_likeLink, 
    &_isHollow {
        background-color: transparent;
        --button-border: none;

        &.Button:not([disabled]):hover {
            background-color: transparent;
            color: #A9824A;
        }
    }
}

a.SaasButtonWidget-Button {
    background-color: #A9824A !important;
    --button-border: #A9824A !important;

    &:hover {
        background-color: #7C5F36 !important;
        border-color: #7C5F36 !important;
    }
}