
@include mobile {
    .HomePage {
        padding-top: 1.5rem;
    }
}

.LayoutGroupWidget { 
    &-Container {
        &_layout_col_1_1_2 {
            margin-top: 5rem !important;
 
            .LayoutGroupWidget-Item {
                &:first-of-type {

                    .CmsBlock-Wrapper {
                        background-color: var(--primary-base-color);
                        
                        li, .phone, h2 {
                            color: white;
                        }

                        h2 {
                            font-size: 1.8rem;
                            margin: auto 0;
                            padding: 1rem 0;
                        }
                    }
                }

                &:nth-of-type(2) {
                    background-color: #E5E5E5;

                    .CmsBlock-Wrapper {
                        iframe {
                            border: 0.1rem solid #B9B9B9 !important;
                            margin-bottom: 1rem;
                        }
                        
                        .phone {
                            border-color: var(--secondary-base-color);
                        }

                        i { 
                            color: black;
                        }
                    }
                }

                &:last-of-type {
                    border: 1px solid var(--secondary-base-color);
                    padding: 1.5rem;

                    .CmsBlock-Wrapper {
                        h2 {
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }
}