@import 'variables';

.HomePageInfo {
    max-width: var(--content-wrapper-width);
    margin: 0 auto 2rem auto;
    padding: 0 1.5rem;

    @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
    }

    &-Column {
        margin-bottom: 2rem;
        
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2.5rem;
            margin: 0;
        }

        &_one {
            @include desktop {
                grid-template-columns: 1fr;
            }
        }
    }

    &-Block {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

        @include mobile {
            &:first-of-type {
                margin-bottom: 2rem;
            }
        }

        .HomePageInfo-Column_one & {
            padding: 3rem;

            h1 {
                text-transform: uppercase;
                font-style: italic;
                margin-bottom: 1.5rem;

                strong {
                    color: var(--secondary-base-color);
                }
            }
            
            p {
                font-size: 1.5rem;
                line-height: 3rem;
                font-weight: 300;
            }
        }

        &Title {
            padding: 1rem 2rem;
            border-bottom: 1px solid var(--secondary-dark-color);

            h2 {
                margin: 0;
                text-transform: uppercase;
                font-size: 1.6rem;
            }
        }

        &Content {
            padding: 2rem;
            
            img {
                width: 100%;
                height: auto;
            }

            li {
                @include reset-list-item;
                line-height: 3rem;
                font-size: 1.6rem;
                display: flex;
                justify-content: flex-start;

                span {
                    font-weight: bold;
                    width: 160px;

                    + span {
                        font-weight: normal;
                    }
                }
            }

            .phone {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 1.6rem;
                margin-top: 1.8rem;
                border-top: 1px solid var(--secondary-light-color);
                font-size: 1.6rem;

                i {
                    margin-right: 1rem;
                    font-size: 3rem;
                    color: var(--secondary-base-color);
                }
            }
        }
    }
}

.BannerGroup-Container {
    
    .Banner {
    
        &Item {
            
            &-Title {
                line-height: 1.1;
                margin: 0;
                text-transform: uppercase;
            }
            
            &-ContentBlock {
                position: absolute;
                bottom: 0;
            }
        }
        
        &Block {
            
            &-Item_type_column_3 {
                min-height: 0;
                height: auto;
                padding-bottom: 66%;
            }
        }
    }
}

.Breadcrumbs {
    background-color: #fff !important;
}

@include desktop {
    .Checkout,
    .CartPage {
        margin-top: 0 !important;
    }
}

@include mobile {
    .CategoryPage {
        .swiper-container {
            width: calc(100% - 3rem) !important;
        }
    }

    .BannerGroup-Container_isFullWidth {
        .swiper-container {
            margin: 0 !important;
        }
    }
}