.SaasButtonWidget {
    

    &-Button {
        --saasbuttonwidget-button-padding: 1.2rem 3rem 1.2rem 1.2rem;        
        background-color: var(--primary-light-color) !important;

        &:after {
            content: '\2192';
            position: absolute;
            right: 0.7rem;
            top: 1.1rem;
            font-size: 1.8rem;
        }
    }
}
