@include desktop {
    .SaasHeader {
        &-NavMenu {
            .MenuLink {
                &-Link {
                    color: #FFF;

                    &:hover {
                        color: #CCC;
                    }
                }
            }

            .MenuContent {
                &-Container {
                    .MenuLink {
                        &-Link {
                            color: #000;
        
                            &:hover {
                                color: #333;
                            }
                        }
                    }
                }
            }

            .MenuAdvanced {
                &-MainItemList {
                    justify-content: flex-start;
                }
            }
        }
    }
}