@import './global';
@import './component/_searchField.override.manual';
@import './component/_menuRegular';
@import './component/_siteUsp';
@import './component/_image';
@import './component/_bannerItem';
@import './component/_bannerBlock';
@import './component/_productCard';
@import './component/_addToCart';
@import './component/_saasButtonWidget';
@import './component/_layoutGroupWidget';
@import './component/_menuButton';
@import './component/_menu';
@import './component/_productAction';
@import './component/_footer';

.BannerGroup-Container .BannerItem_isSlider .BannerItem-Link:after, .BannerBlock-Container .BannerBlock-Item_type_column_2 .BannerItem-Link:after { color:white !important;}
.BannerGroup-Container .BannerItem_isSlider .BannerItem-Link, .BannerBlock-Container .BannerBlock-Item_type_column_2 .BannerItem-Link {background-color:#061534!important;color:white !important;text-transform:uppercase!important;font-size:2rem!important;font-weight:bold!important;}