.BannerBlock {
    &-Item {
        --banner-block-item-height-col-2: 34rem;
    }

    &-Container {
        grid-gap: var(--banner-block-gap);

        .BannerItem {
            &-ContentBlock {
                width: 100% !important;
            }
        }

        .BannerBlock-Item_type_column_2 {
            height: 45.5rem;        

            .BannerItem {
                &-Link {
                    font-size: 1.8rem;
                    height: 7.5rem;
                    color: black;
                    text-transform: uppercase;
                    font-weight: 700;
                    padding: 2.2rem 4rem;

                    &:after {
                        top: 33%;
                    }
                }
            }
        }
    }
}